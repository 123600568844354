<template>
  <b-row>

    <!-- Small -->
     

    <!-- Large -->
    <b-col cols="12">
      <b-form-group>
        <v-select  @search="fetchOptions"
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="fullname"
           :options="categories"
           multiple
          class="select-size-lg p-1"
       
        >
        <template #option="{ photo, fullname }">
         <b-avatar
                    :src="photo"
                    class="mr-1"
                    size="32"
                  />
      <span> {{ fullname  }}</span>
    </template>
     <template #selected-option="{ photo, fullname }">
         <b-avatar
                    :src="photo"
                    class="mr-1"
                    size="32"
                  />
      <span> {{ fullname  }}</span>
    </template>
        </v-select>

      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup ,BAvatar} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BAvatar,
  },
  created () {
    this.fetchCategories()
  
  },
  props :{
    initialCategories:[],
    searchOptions:{},
    searchType:null,
  
  },


  methods:{
     updateSelections(a) {
    
    console.log(a)
  },
  fetchOptions (search, loading) {
    
     return new Promise((resolve, reject) => {
           var uri=`/admin/auth/f/cm/getmembers?p=1&l=10&q=${search}`;
            if(search.length>3) {
        loading(true);
       
       
        axios
          .get(uri)
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
                const { users, total } = responseData;

              this.categories=users;

             loading(false);
       

         

            }
            )
          .catch(error => reject(error))
     
       }
     })

     

},

         fetchCategories( ) {
   //   console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
           var uri=`/admin/auth/f/cm/getmembers?p=1&l=10&q=`;
        axios
          .get(uri)
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
    //      console.log(responseData.data);
               const { users, total } = responseData;

              this.categories=users;


         

            }
            )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
     selected: function (newVal,coldVal) {
     this.$emit("selectionChanged", newVal);

  },
   initialCategories: function (newVal,coldVal) {
      this.selected=newVal;

  }
},
  data() {
    return {
      categories:[],
      options: [],
      selected: this.initialCategories,
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
    }
  }
   

}
</script>